import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from 'next/link';
import styled from '@emotion/styled';

import { COLORS, BOX_SHADOW, GLOBAL, SPACE, URLS, BREAKPOINTS } from '../../constants';
import Navigation from '../navigation';
import Logos from '../logos';
import CompareCars from '../compare-cars';

const Wrapper = styled(Box)`
  background-color: ${COLORS.WHITE};
  box-shadow: ${BOX_SHADOW.NAVIGATION};
  z-index: 1;
`;

const OuterGrid = styled(Grid)`
  @media (min-width: ${BREAKPOINTS.XS}px) {
    min-height: ${GLOBAL.HEADER_HEIGHT[0]};
  }
  @media (min-width: ${BREAKPOINTS.MD}px) {
    min-height: ${GLOBAL.HEADER_HEIGHT[1]};
  }
  @media (min-width: ${BREAKPOINTS.LG}px) {
    min-height: ${GLOBAL.HEADER_HEIGHT[2]};
  }
`;

const InnerLogoGrid = styled(Grid)`
  @media (min-width: ${BREAKPOINTS.XS}px) {
    position: absolute;
    height: 40px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    position: static;
    height: auto;
    transform: translateX(0);
    justify-content: flex-start;
    order: 1;
  }
`;

const InnerNavGrid = styled(Grid)`
  justify-content: space-between;
  @media (min-width: ${BREAKPOINTS.MD}px) {
    order: 2;
    width: auto;
  }

  @media (min-width: ${BREAKPOINTS.XS}px) {
    width: 100%;
  }
`;

const Header = ({
  isHomePage = false,
  primaryNavProps,
  secondaryNavProps = { links: [], title: '' },
  socialLinksProps = { socialIcons: [], tagline: '' },
  compareButtonProps,
}) => {
  return (
    <Wrapper>
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          minHeight={{ xs: GLOBAL.HEADER_HEIGHT[0], md: GLOBAL.HEADER_HEIGHT[1], lg: GLOBAL.HEADER_HEIGHT[2] }}
        >
          <InnerLogoGrid item container xs={8} md={4}>
            <Link href={URLS.HOME}>
              <Logos isHomePage={isHomePage} />
            </Link>
          </InnerLogoGrid>
          <InnerNavGrid item container xs="auto" md={8} alignItems="center">
            <Navigation
              primaryNavProps={primaryNavProps}
              secondaryNavProps={secondaryNavProps}
              socialLinksProps={socialLinksProps}
            />
            <CompareCars compareButtonProps={compareButtonProps} />
          </InnerNavGrid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

Header.propTypes = {
  isHomePage: PropTypes.bool,
  primaryNavProps: PropTypes.shape({
    links: PropTypes.array,
  }).isRequired,
  secondaryNavProps: PropTypes.shape({
    links: PropTypes.array,
    titleSecondary: PropTypes.string,
  }),
  socialLinksProps: PropTypes.shape({
    socialIcons: PropTypes.array,
    tagline: PropTypes.string,
  }),
  compareButtonProps: PropTypes.shape({
    count: PropTypes.number,
    onOpenComparison: PropTypes.func,
  }).isRequired,
};

export default Header;
